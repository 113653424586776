import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

//import { Grid, Row, Col } from 'react-flexbox-grid'
import { Parallax } from 'react-scroll-parallax'

import Layout from '../components/Layout'
import HomeWorks from './home/HomeWorks'
import styles from './home/home.module.scss'

// Import assets
//import magentoLogo from '../i/magento-logo.svg'
import adobeLogo from '../i/adobe-logo.svg'
import txFlag from '../i/tx-flag.svg'

import findImage from '../utils/findImage'

const IndexPage = ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { name: { eq: "home-page-portrait" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location}>
      <Helmet title="Home Page" />

      <section className={styles.homeHello}>
        <div className={styles.portrait}>
          <Parallax y={[0, 10]}>
            <Img
              fluid={findImage(images, 'home-page-portrait')}
              alt="Eugene Bannykh's portrait"
            />
          </Parallax>
        </div>
        <div className={styles.homeHelloText}>
          <h1>
            Eugene Bannykh&nbsp;&mdash;
            <br /> UX designer and developer
          </h1>

          <p>
            I do <Link to="/works/web/">websites</Link>,{' '}
            <Link to="/works/identity/">logos</Link>, and{' '}
            <Link to="/works/print/">prints</Link>. Paying close attention to
            typography and information architecture. Sometimes I&nbsp;do{' '}
            <Link to="/works/illustration/">illustrations</Link> for nice
            people.
          </p>

          <p>
            I live in&thinsp;
            <img src={txFlag} alt="" width="24" />
            &thinsp;Austin. Work at
            <OutboundLink href="https://adobe.com/" className={styles.magento}>
              &thinsp;
              <img
                src={adobeLogo}
                alt=""
                width="16"
                height="16"
                className={styles.logo}
              />
              &nbsp;
              <span>Adobe</span>
            </OutboundLink>.
          </p>
        </div>
      </section>

      <section className={styles.homeWorks}>
        <HomeWorks />
      </section>
    </Layout>
  )
}

export default IndexPage
